import { template as template_dd0b96b6062c476888dc84d709b80b42 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_dd0b96b6062c476888dc84d709b80b42(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
