import { template as template_2e618d976da643de851a8dda6b0d5011 } from "@ember/template-compiler";
const WelcomeHeader = template_2e618d976da643de851a8dda6b0d5011(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
